<div class="container py-4" style="min-height: calc(100vh - 75px)">
  <div class="card-deck">
    <!-- Profile Information Card -->
    <div class="card bg-dark text-white">
      <div class="card-header bg-secondary">
        <h5 class="mb-0 d-flex align-items-center">
          <i class="material-icons align-middle mr-2">person</i>
          {{ 'userProfile.info' | translate }}
        </h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="form-label">{{ 'userProfile.firstName' | translate }}</div>
            <div class="text-white-50">{{ user.firstName }}</div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="form-label">{{ 'userProfile.lastName' | translate }}</div>
            <div class="text-white-50">{{ user.lastName }}</div>
          </div>
        </div>
        <div class="mb-3">
          <div class="form-label">{{ 'userProfile.email' | translate }}</div>
          <div class="text-white-50">{{ user.email || "Not Setup" }}</div>
        </div>
        <div class="mb-3">
          <div class="form-label">{{ 'userProfile.phone' | translate }}</div>
          <div
            class="text-white-50" *ngIf="user.userProfileDTO.phone"
          >{{ formatPhoneNumber(user.userProfileDTO.phone) }}
          </div>
          <div class="text-white-50" *ngIf="!user.userProfileDTO.phone">Not Setup</div>
        </div>
      </div>
    </div>

    <!-- Two-Factor Authentication (Deactivated) Card -->
    <div class="card bg-dark text-white" *ngIf="showTwoFactorSettings && !user.userProfileDTO.twoFactorAuthentication">
      <div class="card-header bg-secondary">
        <h5 class="mb-0 d-flex align-items-center">
          <i class="material-icons align-middle mr-2">security</i>
          {{ 'userProfile.twoFactor' | translate }}
        </h5>
      </div>
      <div class="card-body">
        <p class="text-white-50">{{ 'userProfile.twoFactorExplain' | translate }}</p>

        <button
          class="btn btn-outline-primary d-flex align-items-center justify-content-center"
          routerLink="/setup/mfa"
          [disabled]="isSubmitting"
        >
          <i class="material-icons align-middle mr-1">add</i>
          {{ 'userProfile.button.setupTwoFactor' | translate }}
        </button>
      </div>
    </div>

    <!-- Two-Factor Authentication (Activated) Card -->
    <div class="card bg-dark text-white" *ngIf="showTwoFactorSettings && user.userProfileDTO.twoFactorAuthentication">
      <div class="card-header bg-secondary">
        <h5 class="mb-0 d-flex align-items-center">
          <i class="material-icons align-middle mr-2">security</i>
          {{ 'userProfile.twoFactor' | translate }}
        </h5>
      </div>
      <div class="card-body">
        <!-- Authenticator Section -->
        <div class="mb-4">
          <div>
            <div class="mb-1">
              <h6 class="mb-1">{{ 'userProfile.totp' | translate }}</h6>
              <p class="text-white-50 mb-0" *ngIf="!user.userProfileDTO.mfaOptionTotp">
                {{ 'userProfile.totpDisabled' | translate }}
              </p>
              <p class="text-success mb-0 d-flex align-items-center" *ngIf="user.userProfileDTO.mfaOptionTotp">
                <i class="material-icons align-middle mr-1">check_circle</i>
                {{ 'userProfile.totpEnabled' | translate }}
              </p>
            </div>
            <button
              class="btn btn-outline-primary d-flex align-items-center justify-content-center"
              (click)="setupAuthenticator()"
              *ngIf="!user.userProfileDTO.mfaOptionTotp"
              [disabled]="isSubmitting"
            >
              <i class="material-icons align-middle mr-1">add</i>
              {{ 'userProfile.button.addTOTP' | translate }}
            </button>
            <button
              class="btn btn-outline-info d-flex align-items-center justify-content-center"
              (click)="setupAuthenticator()"
              *ngIf="user.userProfileDTO.mfaOptionTotp"
              [disabled]="isSubmitting"
            >
              <i class="material-icons align-middle mr-1">refresh</i>
              {{ 'userProfile.button.resetTOTP' | translate }}
            </button>
          </div>
        </div>

        <!-- SMS Section -->
        <div class="mb-4">
          <div class="mb-1">
            <h6 class="mb-1">{{ 'userProfile.sms' | translate }}</h6>
            <p class="text-white-50 mb-0" *ngIf="!user.userProfileDTO.mfaOptionSms">
              {{ 'userProfile.smsDisabled' | translate }}
            </p>
            <p class="text-success mb-0" *ngIf="user.userProfileDTO.mfaOptionSms">
              <i class="material-icons align-middle mr-1">check_circle</i>
              {{ 'userProfile.smsEnabled' | translate }}
            </p>
          </div>
          <button
            class="btn btn-outline-primary d-flex align-items-center justify-content-center"
            (click)="setupSms()"
            *ngIf="!user.userProfileDTO.mfaOptionSms"
            [disabled]="isSubmitting"
          >
            <i class="material-icons align-middle mr-1">add</i>
            {{ 'userProfile.button.addSMS' | translate }}
          </button>
          <button
            class="btn btn-outline-info d-flex align-items-center justify-content-center"
            (click)="setupSms()"
            *ngIf="user.userProfileDTO.mfaOptionSms"
            [disabled]="isSubmitting"
          >
            <i class="material-icons align-middle mr-1">refresh</i>
            {{ 'userProfile.button.resetSMS' | translate }}
          </button>
        </div>

        <!-- Recovery Code Section -->
        <div>
          <div>
            <h6 class="mb-2">{{ 'userProfile.recoveryCode' | translate }}</h6>
          </div>
          <button
            class="btn btn-outline-danger d-flex align-items-center justify-content-center"
            (click)="resetRecovery()"
            [disabled]="isSubmitting"
          >
            <i class="material-icons align-middle mr-1">refresh</i>
            {{ 'userProfile.button.resetRecovery' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
